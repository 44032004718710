<template>
    <div>
        <LoaderDefault v-if="isLoading" />
        <div v-else>
            <DefaultHeader2 :routeBack="'/taips'" :titleHeader="'Actualizar documento'" />
            <a-row :gutter="24" type="flex">
                <a-col :span="24" class="mb-24">
                    <section class="content-form">
                        <div class="content-inputs" id="content-inputs">
                            <label for="title" id="number-pocess-label">Titulo *</label>
                            <input v-model="dataTaip.title" class="input" name="title" type="text"
                                placeholder="Titulo del la transaparencia y acceso" id="title" required>
                        </div>
                    </section>

                    <section class="sec-anexos" id="sec-anexos">
                        <div class="header-sec-anexos">
                            <h1 class="title-section-anexos">Listado de sibtitulos</h1>
                            <i class="fa-solid fa-circle-info"></i>
                        </div>
                        <div class="content-list-items-add">

                            <!-- <el-tag v-for="(tag, index) in listItemTaip" :key="index" closable :disable-transitions="false"
                                @close="showConfirmation(tag, index, true)">
                                {{ tag.subtitle }}                                
                            </el-tag> -->

                            <div v-for="(item, index) in listItemTaip" :key="index">
                                <div class="content-item">
                                    <h2 class="name-item-added">{{ item.subtitle }}</h2>
                                    <i class="fa-sharp fa-solid fa-file-pdf icon-pdf"></i>
                                    <i class="el-icon-edit icon-added" @click="showFormEditNameSuptitle(item, index)"></i>
                                    <i class="el-icon-close text-danger icon-added"
                                        @click="showConfirmation(item, index, false)"></i>
                                </div>
                            </div>
                        </div>
                        <button class="btn-save-item" @click="showFormEdit()">Nuevo subtitulo</button>
                    </section>

                    <section class="container-form-edit" id="container-form-edit"><span class="close-btn"
                            @click="closeFormEdi()"><a href="#">X</a></span>
                        <div class="show">
                            <h2 class="title-update">Añadir nuevo subtitulo</h2>
                            <div class="content-inputs-modal" id="content-inputs">
                                <label for="name_of_item">Item *</label>
                                <input class="input" v-model="dataNewSubtitle.subtitle" name="name_of_item" type="text"
                                    placeholder="Nombre del item" id="name_of_item" required>
                                <span v-if="errors.item" class="error-message">{{ errors.item }}</span>
                            </div>
                            <div class="list-new-subtiles">
                                <br>
                                <h5 class="title-lis-new">Listado de documento</h5>

                                <div class="list">

                                    <el-tag v-for="(tag, index) in listNewDocuments" :key="index" closable
                                        :disable-transitions="false" @close="showConfirmation(tag, index, true)">
                                        {{ tag.title }}
                                    </el-tag>
                                </div>
                            </div>
                            <div class="container-edit-two">
                                <div class="content-inputs-modal" id="content-inputs">
                                    <label class="label" id="label" for="name">Nombre *</label>
                                    <input id="name" v-model="dataDocumentTaip.title" class="input input-anexo" name="name"
                                        type="text" placeholder="Nombre" required>
                                    <span v-if="errors.name" class="error-message">{{ errors.name }}</span>

                                </div>
                                <div class="div-select-custom">
                                    <input id="file-5" type="file" @click="loadFilePdf" @change="loadFilePdf()"
                                        name="file-5" class="inputfile inputfile-5 input-anexo" required />
                                    <label id="label" for="file-5" class="label-file label">
                                        <figure>
                                            <svg xmlns="http://www.w3.org/2000/svg" class="iborrainputfile" width="20"
                                                height="17" viewBox="0 0 20 17">
                                                <path
                                                    d="M10 0l-5.2 4.9h3.3v5.1h3.8v-5.1h3.3l-5.2-4.9zm9.3 11.5l-3.2-2.1h-2l3.4 2.6h-3.5c-.1 0-.2.1-.2.1l-.8 2.3h-6l-.8-2.2c-.1-.1-.1-.2-.2-.2h-3.6l3.4-2.6h-2l-3.2 2.1c-.4.3-.7 1-.6 1.5l.6 3.1c.1.5.7.9 1.2.9h16.3c.6 0 1.1-.4 1.3-.9l.6-3.1c.1-.5-.2-1.2-.7-1.5z">
                                                </path>
                                            </svg>
                                        </figure>
                                    </label>
                                    <h6 class="name-pdf-selected label" id="name-pdf-selected">Seleccionar archivo *</h6>
                                    <span v-if="errors.file" class="error-message">{{ errors.file }}</span>
                                </div>
                            </div>
                            <button class="btn-save-item" @click="addItemAnexoToArray()">Añadir</button>
                            <button class="btn-save-data" @click="saveNewSubtitle()">Guardar</button>
                        </div>
                    </section>
                    <button class="btn-save-item" @click="updateName()">Guardar cambios en el documento</button>

                    <section class="container-form-edit-subtitle" id="container-form-edit-subtitle"><span class="close-btn"
                            @click="closeFormEdi()"><a href="#">X</a></span>
                        <div class="show">
                            <h2 class="title-update">Actualizar nombre de subtitulo</h2>

                            <div class="content-inputs" id="content-inputs">
                                <label for="name_of_item">Subtitulo *</label>
                                <input class="input" v-model="newsubtitle.nameSubtitle" name="name_of_item" type="text"
                                    placeholder="Nombre del item" id="name_of_item" required>
                                <span v-if="errors.item" class="error-message">{{ errors.item }}</span>

                            </div>
                            <button class="btn-save-data" @click="updateSubtitle()">Actualizar</button>
                        </div>
                    </section>
                </a-col>
            </a-row>
        </div>
    </div>
</template>

<script>
import DefaultHeader2 from '../../../components/Headers/DefaultHeader2.vue'
import LoaderDefault from '../../../components/Loaders/LoaderDefault.vue';
import { db, storage } from '../../../../firebaseConfig';

export default {
    components: {
        DefaultHeader2,
        LoaderDefault
    },
    data() {
        return {
            isLoading: false,

            itemsTaip: [],
            _idParam: "",

            isLoading: false,
            selectedFile: File,
            dataTaip: [],
            newsubtitle: {
                nameSubtitle: '',
                position: 0
            },

            listNewDocuments: [],
            listItemTaip: [],
            dataDocumentTaip: {
                _idTitle: "",
                title: "",
                file: File,
                urlPdf: ""
            },

            dataNewSubtitle: {
                subtitle: '',
                _idSubtitle: '',
                data: []
            },
            errors: {},
        }
    },
    mounted() {
        this._idParam = this.$route.params._id
        this.getInfoTaip(this._idParam)
    },
    methods: {
        async getInfoTaip(_idDoc) {
            this.isLoading = true
            await db.collection('taips')
                .doc(_idDoc)
                .get()
                .then((querySnapshot) => {
                    this.dataTaip = querySnapshot.data()
                    this.listItemTaip = querySnapshot.data().data                    
                    this.isLoading = false
                })
        },

        closeFormEdi() {
            const layoutForm = document.getElementById('container-form-edit')
            const layoutFormTwo = document.getElementById('container-form-edit-subtitle')
            layoutForm?.classList.add('container-form-edit')
            layoutFormTwo?.classList.add('container-form-edit-subtitle')
            layoutForm?.classList.remove('container-form-edit-visible')
            layoutFormTwo?.classList.remove('container-form-edit-subtitle-visible')
        },

        showFormEdit() {
            const layoutForm = document.getElementById('container-form-edit')
            layoutForm?.classList.remove('container-form-edit')
            layoutForm?.classList.add('container-form-edit-visible')
        },

        showFormEditNameSuptitle(positionSubTitle, index) {
            this.newsubtitle.nameSubtitle = positionSubTitle.subtitle
            this.newsubtitle.position = index
            const layoutForm = document.getElementById('container-form-edit-subtitle')
            layoutForm?.classList.remove('container-form-edit-subtitle')
            layoutForm?.classList.add('container-form-edit-subtitle-visible')
        },

        addItemAnexoToArray() {
            if (this.dataDocumentTaip.title != '') {
                if (this.selectedFile instanceof File && this.selectedFile.size > 0) {
                    this.dataDocumentTaip.file = this.selectedFile
                    this.listNewDocuments.push(this.dataDocumentTaip)
                    this.dataDocumentTaip = {}
                } else {
                    this.$swal
                        .fire({
                            title: 'Información importante',
                            text: 'Para añadir un nuevo elemento es obligatorio seleccionar un documento',
                            icon: 'warning',
                            showCancelButton: false,
                            confirmButtonText: 'Aceptar',
                            confirmButtonColor: "#45A825",
                        })
                }
            }
        },

        removeItemAnexoToArray(item) {
            const index = this.listNewDocuments.indexOf(item);
            if (index > -1) this.listNewDocuments.splice(index, 1);
        },

        loadFilePdf() {
            const input = document.getElementById('file-5')
            input.accept = "application/pdf";
            input?.addEventListener('change', (e) => {
                const target = e.target
                if (target.files && target.files.length > 0) {
                    const nameSelected = document.getElementById('name-pdf-selected');
                    nameSelected.innerHTML = '' + target.files[0].name;
                    this.selectedFile = target.files[0]
                }
            });
        },

        showConfirmation(itemData, poDelFirestore, isDataLocal) {
            this.$swal
                .fire({
                    title: 'Eliminar documento',
                    text: '¿Estás seguro de realizar esta acción?',
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonText: 'Aceptar',
                    confirmButtonColor: "#45A825",
                    cancelButtonText: 'Cancelar',
                })
                .then((result) => {
                    if (result.value) {
                        if (isDataLocal) {
                            this.removeItemAnexoToArray(itemData)
                        } else {
                            this.deleteFileOfDocument(poDelFirestore)
                        }
                    };
                });
        },

        validateFirstForm() {
            let isValid = true
            if (!this.dataNewSubtitle.subtitle != '') {
                this.errors.item = 'Por favor, introduce el nombre del item.';
                isValid = false
                return
            }

            if (!this.dataDocumentTaip.title != '' && this.listNewDocuments.length <= 0) {
                this.errors.name = 'Por favor, introduce el nombre.';
                isValid = false
                return
            }

            if (!(this.selectedFile instanceof File && this.selectedFile.size > 0)) {
                this.errors.file = 'Por favor, selecciona un archivo.';
                isValid = false;
            }
            if (this.listNewDocuments.length <= 0) {
                this.$swal
                    .fire({
                        title: 'Información importante',
                        text: 'Para realizar esta acción es obligatorio haber añadido minimo un documento',
                        icon: 'warning',
                        showCancelButton: false,
                        confirmButtonText: 'Aceptar',
                        confirmButtonColor: "#45A825",
                    })
                isValid = false;
            }

            return isValid
        },
        async saveNewSubtitle() {
            this.errors = {};
            if (!this.validateFirstForm()) {
                return;
            }

            this.isLoading = true
            this.closeFormEdi()
            const docRef = await db.collection('taips').doc(this._idParam)
            const doc = await docRef.get()
            let currentData = await doc.data().data || [];
            this.dataNewSubtitle._idSubtitle = this.dataNewSubtitle.subtitle
            const storageRef = storage.ref();
            const dataDocsPromises = this.listNewDocuments.map(async (doc) => {
                const pdfRef = storageRef
                    .child('pdfsDocumentsTaips')
                    .child(this._idParam)
                    .child(this.dataNewSubtitle.subtitle)
                    .child(doc.title);

                await pdfRef.put(doc.file);
                const pdfUrl = await pdfRef.getDownloadURL();
                const taipDocData = {
                    _idTitle: doc.title,
                    title: doc.title,
                    urlPdf: pdfUrl
                };
                return taipDocData;
            });
            const dataPromise = await Promise.all(dataDocsPromises);
            this.dataNewSubtitle.data = dataPromise
            currentData.push(this.dataNewSubtitle)
            await docRef.update({ ['data']: currentData })
            this.$swal
                .fire({
                    title: 'Actualización exitosa',
                    text: 'Se ha actualizado con exito el TAIP',
                    icon: 'success',
                    showCancelButton: false,
                    confirmButtonText: 'Aceptar',
                    confirmButtonColor: "#45A825",
                })
            await this.getInfoTaip(this._idParam)
            this.dataDocumentTaip = []
            this.dataDocumentTaip = {}
            this.listNewDocuments = []
            this.isLoading = false

        },

        navToIndexTender() { this.$router.push('/AdminDashboard/IndexTaip') },


        async updateName() {
            this.isLoading = true
            const docRef = await db.collection('taips').doc(this._idParam)
            await docRef.update({ "title": this.dataTaip.title })
            this.$swal
                .fire({
                    title: 'Actualización exitosa',
                    text: 'La iformación se ha actualizado exitosamente',
                    icon: 'success',
                    showCancelButton: false,
                    confirmButtonText: 'Aceptar',
                    confirmButtonColor: "#45A825",
                })
            this.isLoading = false
        },
        async updateSubtitle() {
            if (this.newsubtitle.nameSubtitle != '') {
                this.isLoading = true
                this.closeFormEdi()
                const docRef = db.collection('taips').doc(this._idParam);
                const doc = await docRef.get();
                const data = doc.data().data;
                const nestedData = data;
                nestedData[this.newsubtitle.position].subtitle = this.newsubtitle.nameSubtitle
                await docRef.update({
                    'data': nestedData
                });
                this.$swal
                    .fire({
                        title: 'Actualización exitosa',
                        text: 'La información se ha actualizado exitosamente',
                        icon: 'success',
                        showCancelButton: false,
                        confirmButtonText: 'Aceptar',
                        confirmButtonColor: "#45A825",
                    })
                this.closeFormEdi()
                this.getInfoTaip(this._idParam)
            } else {
                this.$swal
                    .fire({
                        title: 'Información importante',
                        text: 'Recuerda que el campo de nombre es obligatorio',
                        icon: 'warning',
                        showCancelButton: false,
                        confirmButtonText: 'Aceptar',
                        confirmButtonColor: "#45A825",
                    })
                this.isLoading = false
            }
        },


        async deleteFileOfDocument(indexOne) {
            this.isLoading = true
            const storageRef = storage.ref();
            const pdfFolderRef = storageRef.child('pdfsDocumentsTaips')
                .child(this._idParam)
                .child(this.listItemTaip[indexOne]._idSubtitle);
            const fileList = await pdfFolderRef.listAll();

            fileList.items.forEach(async (fileRef) => {
                try {
                    await fileRef.delete();
                } catch (error) {                    
                }
            });
            try {
                await this.removeDocumentFirestoreDataOne(indexOne)
                await pdfFolderRef.delete();
            } catch (error) {                
                this.isLoading = false
            }
        },

        async removeDocumentFirestoreDataOne(indexOne) {
            this.isLoading = true
            const docRef = await db.collection('taips').doc(this._idParam);
            const doc = await docRef.get();
            const data = await doc.data();
            const nestedData = data.data

            nestedData.splice(indexOne, 1);
            await docRef.update({
                ['data']: nestedData
            });
            await this.getInfoTaip(this._idParam)
        }
    },
}
</script>